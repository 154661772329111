<template>
  <section class="dtc-main-section mt-2 mr-2">
    <ViewStudentInfo></ViewStudentInfo>
    <section class="search-block">
      <h5 class="big-title">
        <span class="mr-3">編輯印(請)領清冊核銷明細表</span>
        {{ editItem.BYear }}年度 第一學期 培育學校: {{ editItem.school }}
      </h5>

      <div class="dtc-search1">
        <b-button variant="light" @click="openStudentInfo"
          >檢視公費生資訊</b-button
        >
        <b-button variant="primary" @click="reviseAllData">修正</b-button>
        <b-button variant="warning" @click="saveAllData">儲存</b-button>
        <b-button variant="success" @click="goBackLastPage">返回</b-button>
        <b-button variant="danger" @click="openPrintPage">列印</b-button>
      </div>
    </section>
    <header class="dtc-grid-header ml-1 my-dark">
      <div>序</div>
      <div>學系</div>
      <div>年級</div>
      <div>學生姓名</div>
      <div class="header-column4">
        <div class="header-column4-row1">
          <div class="header-column4-row1-word">實報實銷類</div>
        </div>
        <div class="header-column4-row2">
          <div>學雜費</div>
          <div></div>
          <div>教學實習費</div>
        </div>
        <div class="header-column4-row3">
          <div>含退輔基金、<br />實驗費</div>
          <div class="header-column4-total" style="border-top:0px">
            學生平安保險費
          </div>
          <div>含電腦網路使用費</div>
        </div>
      </div>

      <div class="header-column5">
        <div class="header-column5-row1">
          <div class="header-column5-row1-word">定額核銷類</div>
        </div>
        <div class="header-column5-row2">
          <div>膳食費</div>
          <div>零用津貼</div>
          <div>課業費</div>
          <div>書籍費</div>
          <div>制服費</div>
        </div>
        <div class="header-column5-row3">
          <div>3,250元/月*6個月=19,500元/每學期/人</div>
          <div>3,500元/月*6個月=21,000元/每學期/人</div>
          <div>1,000元/每學期/人</div>
          <div>4,000元/每學期/人</div>
          <div>2,500元/每學期/人</div>
        </div>
      </div>
      <div class="header-column6">
        <div class="header-column6-row1">
          <div class="header-column6-row1-word">
            在補助額度內檢附單據或證明類
          </div>
        </div>
        <div class="header-column6-row2">
          <div>返鄉旅費</div>
          <div>住宿費</div>
        </div>
        <div class="header-column6-row3">
          <div>
            交通費核實支付5,000元/每學期/人
          </div>
          <div>
            3,000元/月*6個月=18,000元/每學期/人
          </div>
        </div>
      </div>

      <div>合計</div>
    </header>

    <!-- <main v-if="!items.length" class="dtc-grid-header main-dtc-body">
      <div
        style="grid-column: 1 / -1;padding: 20px 0px;font-size:24px;color:#000;"
      >
        暫無資料
      </div>
    </main> -->
    <main
      class="dtc-grid-main main-dtc-body ml-1"
      v-for="(item, i) in items"
      :key="i"
      style="color:#dee2e5;"
      :style="
        i % 2 == 0 ? `background-color: #fff;` : `background-color: #efefef;`
      "
    >
      <div>{{ i + 1 }}</div>
      <div :title="item.MajorName">{{ item.MajorName || "" }}</div>
      <div :title="item.GradeName">{{ item.GradeName || "" }}</div>
      <div :title="item.Student">{{ item.Student || "" }}</div>
      <FormatPriceInput
        :key="`Fee_1${i}${compontKey}`"
        :data="item.Fee_1"
        :keyIndex="i"
        place="Fee_1"
        :person="item"
        @update="saveSingleData"
      ></FormatPriceInput>
      <FormatPriceInput
        :key="`Fee_2${i}${compontKey}`"
        :data="item.Fee_2"
        :keyIndex="i"
        place="Fee_2"
        :person="item"
        @update="saveSingleData"
      ></FormatPriceInput>

      <FormatPriceInput
        :key="`Fee_3${i}${compontKey}`"
        :data="item.Fee_3"
        :keyIndex="i"
        place="Fee_3"
        :person="item"
        @update="saveSingleData"
      ></FormatPriceInput>
      <div :title="item.Fee_4" class="price-content">
        {{ $formatPrice(item.Fee_4) || "" }}
      </div>
      <div :title="item.Fee_5" class="price-content">
        {{ $formatPrice(item.Fee_5) || "" }}
      </div>
      <div :title="item.Fee_6" class="price-content">
        {{ $formatPrice(item.Fee_6) || "" }}
      </div>
      <div :title="item.Fee_7" class="price-content">
        {{ $formatPrice(item.Fee_7) || "" }}
      </div>
      <div :title="item.Fee_8" class="price-content">
        {{ $formatPrice(item.Fee_8) || "" }}
      </div>
      <FormatPriceInput
        :key="`Fee_10${i}${compontKey}`"
        :data="item.Fee_10"
        :keyIndex="i"
        place="Fee_10"
        :person="item"
        @update="saveSingleData"
      ></FormatPriceInput>
      <FormatPriceInput
        :key="`Fee_11${i}${compontKey}`"
        :data="item.Fee_11"
        :keyIndex="i"
        place="Fee_11"
        :person="item"
        @update="saveSingleData"
      ></FormatPriceInput>
      <div :title="item.Subtotal" class="price-content">
        {{ $formatPrice(item.Subtotal) || "" }}
      </div>
    </main>
    <footer
      class="dtc-grid-footer main-dtc-body ml-1"
      style="color: #212529; background-color: #fff;"
    >
      <div class="column1-content">總計</div>
      <div class="price-content">
        {{ $formatPrice(totalPriceObject.Fee_1) }}
      </div>
      <div class="price-content">
        {{ $formatPrice(totalPriceObject.Fee_2) }}
      </div>

      <div class="price-content">
        {{ $formatPrice(totalPriceObject.Fee_3) }}
      </div>
      <div class="price-content">
        {{ $formatPrice(totalPriceObject.Fee_4) }}
      </div>
      <div class="price-content">
        {{ $formatPrice(totalPriceObject.Fee_5) }}
      </div>
      <div class="price-content">
        {{ $formatPrice(totalPriceObject.Fee_6) }}
      </div>
      <div class="price-content">
        {{ $formatPrice(totalPriceObject.Fee_7) }}
      </div>
      <div class="price-content">
        {{ $formatPrice(totalPriceObject.Fee_8) }}
      </div>
      <div class="price-content">
        {{ $formatPrice(totalPriceObject.Fee_10) }}
      </div>
      <div class="price-content">
        {{ $formatPrice(totalPriceObject.Fee_11) }}
      </div>
      <div class="price-content">
        {{ $formatPrice(totalPriceObject.Subtotal) }}
      </div>
    </footer>
  </section>
</template>

<script>
import { store } from "@/store/global.js";
const year = new Date().getFullYear() - 1911;
let years = new Array(8).fill().map((s, i) => year + 1 - i);
import ViewStudentInfo from "./ViewStudentInfo";
import FormatPriceInput from "./FormatPriceInput";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
export default {
  name: "ShowTable",
  data() {
    return {
      indexTable: 0,
      tableTitle: "",

      items: [],
      headers: [],
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      editItem: {},
      seqNo: "",
      supplement: "",
      applyNumber: "",
      applyDate: "",
      totalPriceObject: {
        People: 0,
        Fee_1: 0,
        Fee_2: 0,
        Fee_3: 0,
        Fee_4: 0,
        Fee_5: 0,
        Fee_6: 0,
        Fee_7: 0,
        Fee_8: 0,
        Fee_10: 0,
        Fee_11: 0,

        Subtotal: 0,
        temp: null,
      },
      compontKey: 0,
      originArray: [],
    };
  },
  components: { ViewStudentInfo, FormatPriceInput },
  computed: {
    nowTab() {
      return store.nowTab;
    },
  },
  methods: {
    saveSingleData(obj) {
      if (obj.isUp) {
        this.$bvToast.toast(`超過上限值，系統將自動帶入上限值`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
      this.items[obj.keyIndex][obj.place] = obj.data;
      this.renewTotalValue();
    },
    renewTotalValue() {
      this.items = this.items.map((s) => {
        s.Fee_1 = `${s["Fee_1"]}`.includes(",")
          ? +`${s["Fee_1"]}`.replace(/,/g, "")
          : +s.Fee_1;
        s.Fee_2 = `${s["Fee_2"]}`.includes(",")
          ? +`${s["Fee_2"]}`.replace(/,/g, "")
          : +s.Fee_2;
        s.Fee_3 = `${s["Fee_3"]}`.includes(",")
          ? +`${s["Fee_3"]}`.replace(/,/g, "")
          : +s.Fee_3;
        s.Fee_10 = `${s["Fee_10"]}`.includes(",")
          ? +`${s["Fee_10"]}`.replace(/,/g, "")
          : +s.Fee_10;
        s.Fee_11 = `${s["Fee_11"]}`.includes(",")
          ? +`${s["Fee_11"]}`.replace(/,/g, "")
          : +s.Fee_11;

        return s;
      });
      this.items = this.items.map((s) => {
        s.Subtotal =
          s.Fee_1 +
          s.Fee_2 +
          s.Fee_3 +
          s.Fee_4 +
          s.Fee_5 +
          s.Fee_6 +
          s.Fee_7 +
          s.Fee_8 +
          s.Fee_9 +
          s.Fee_10 +
          s.Fee_11;
        return s;
      });
      this.totalPriceObject.Fee_1 = 0;
      this.totalPriceObject.Fee_2 = 0;
      this.totalPriceObject.Fee_3 = 0;
      this.totalPriceObject.Fee_10 = 0;
      this.totalPriceObject.Fee_11 = 0;

      this.totalPriceObject.Subtotal = 0;

      this.items.forEach((s) => {
        this.totalPriceObject.Fee_1 += s.Fee_1;
        this.totalPriceObject.Fee_2 += s.Fee_2;
        this.totalPriceObject.Fee_3 += s.Fee_3;
        this.totalPriceObject.Fee_10 += s.Fee_10;
        this.totalPriceObject.Fee_11 += s.Fee_11;

        this.totalPriceObject.Subtotal += s.Subtotal;
      });
    },
    async getData() {
      const url = `Budgeting/GetList?byear=${this.editItem.BYear}&term=${this.editItem.Term}&schoolcode=${this.editItem.SchoolCode}&category=N`;
      try {
        const { SeqNo, budgeting_detail } = await window.axios.get(url);

        this.totalPriceObject.Fee_1 = 0;
        this.totalPriceObject.Fee_2 = 0;
        this.totalPriceObject.Fee_3 = 0;
        this.totalPriceObject.Fee_4 = 0;
        this.totalPriceObject.Fee_5 = 0;
        this.totalPriceObject.Fee_6 = 0;
        this.totalPriceObject.Fee_7 = 0;
        this.totalPriceObject.Fee_8 = 0;
        this.totalPriceObject.Fee_9 = 0;
        this.totalPriceObject.Fee_10 = 0;
        this.totalPriceObject.Fee_11 = 0;
        this.totalPriceObject.Subtotal = 0;

        budgeting_detail.forEach((s, index, array) => {
          this.totalPriceObject.People += s.NumberOfPeople;
          this.totalPriceObject.Fee_1 += s.Fee_1;
          this.totalPriceObject.Fee_2 += s.Fee_2;
          this.totalPriceObject.Fee_3 += s.Fee_3;
          this.totalPriceObject.Fee_4 += s.Fee_4;
          this.totalPriceObject.Fee_5 += s.Fee_5;
          this.totalPriceObject.Fee_6 += s.Fee_6;
          this.totalPriceObject.Fee_7 += s.Fee_7;
          this.totalPriceObject.Fee_8 += s.Fee_8;
          this.totalPriceObject.Fee_9 += s.Fee_9;
          this.totalPriceObject.Fee_10 += s.Fee_10;
          this.totalPriceObject.Fee_11 += s.Fee_11;

          this.totalPriceObject.Subtotal += s.Subtotal;
        });
        let arr = Boolean(budgeting_detail) ? budgeting_detail : [];
        arr = arr.map((s) => {
          s.editMode = false;
          s.Fee_1 = `${s.Fee_1}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          s.Fee_2 = `${s.Fee_2}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          s.Fee_3 = `${s.Fee_3}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          s.Fee_10 = `${s.Fee_10}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          s.Fee_11 = `${s.Fee_11}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return s;
        });
        this.items = JSON.parse(JSON.stringify(arr));
        this.originArray = JSON.parse(JSON.stringify(arr));
        this.seqNo = SeqNo;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    openStudentInfo() {
      this.$bvModal.show("viewStudentInfo");
    },
    async reviseAllData() {
      let result = await Swal.fire({
        title: "",
        text: "您確定放棄各項編輯費用並重新帶入各年級、學生人數及冊費設定",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "確定",
        cancelButtonText: "取消",
      });
      if (result.isConfirmed) {
        await window.axios.get(`Budgeting/FixDetail?seqNo=${this.seqNo}`);
        // await window.axios.get(`Budgeting/Fix?seqNo=${this.seqNo}`);
        await this.getData();
        this.compontKey += 1;
        Swal.fire("各年級、學生人數及註冊費設定", "已成功修正", "success");
      }
    },
    async goBackLastPage() {
      let arr1 = [...this.items];
      let arr2 = [...this.originArray];
      arr1 = arr1.map((s) => ({
        SeqNo: s.SeqNo,
        Bno: s.Bno,
        Fee_1: `${s["Fee_1"]}`.includes(",")
          ? +`${s["Fee_1"]}`.replace(/,/g, "")
          : +s.Fee_1,
        Fee_2: `${s["Fee_2"]}`.includes(",")
          ? +`${s["Fee_2"]}`.replace(/,/g, "")
          : +s.Fee_2,
        Fee_3: `${s["Fee_3"]}`.includes(",")
          ? +`${s["Fee_3"]}`.replace(/,/g, "")
          : +s.Fee_3,
        Fee_10: `${s["Fee_10"]}`.includes(",")
          ? +`${s["Fee_10"]}`.replace(/,/g, "")
          : +s.Fee_10,
        Fee_11: `${s["Fee_11"]}`.includes(",")
          ? +`${s["Fee_11"]}`.replace(/,/g, "")
          : +s.Fee_11,
        Fee_12: `${s["Fee_12"]}`.includes(",")
          ? +`${s["Fee_12"]}`.replace(/,/g, "")
          : +s.Fee_12,
      }));
      arr2 = arr2.map((s) => ({
        SeqNo: s.SeqNo,
        Bno: s.Bno,
        Fee_1: `${s["Fee_1"]}`.includes(",")
          ? +`${s["Fee_1"]}`.replace(/,/g, "")
          : +s.Fee_1,
        Fee_2: `${s["Fee_2"]}`.includes(",")
          ? +`${s["Fee_2"]}`.replace(/,/g, "")
          : +s.Fee_2,
        Fee_3: `${s["Fee_3"]}`.includes(",")
          ? +`${s["Fee_3"]}`.replace(/,/g, "")
          : +s.Fee_3,
        Fee_10: `${s["Fee_10"]}`.includes(",")
          ? +`${s["Fee_10"]}`.replace(/,/g, "")
          : +s.Fee_10,
        Fee_11: `${s["Fee_11"]}`.includes(",")
          ? +`${s["Fee_11"]}`.replace(/,/g, "")
          : +s.Fee_11,
        Fee_12: `${s["Fee_12"]}`.includes(",")
          ? +`${s["Fee_12"]}`.replace(/,/g, "")
          : +s.Fee_12,
      }));
      arr1 = JSON.stringify(arr1);
      arr2 = JSON.stringify(arr2);

      if (arr1 != arr2) {
        let result = await Swal.fire({
          title: "",
          text: "您確定放棄各項編輯費用並返回上一頁",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "確定",
          cancelButtonText: "取消",
        });
        if (result.isConfirmed) {
          this.$router.push("/ruralbudgetmanagetab");
        }
      } else {
        this.$router.push("/ruralbudgetmanagetab");
      }
    },
    async saveAllData() {
      let arr = [...this.items];
      arr = arr.map((s) => ({
        SeqNo: s.SeqNo,
        Bno: s.Bno,
        Fee_1: `${s["Fee_1"]}`.includes(",")
          ? +`${s["Fee_1"]}`.replace(/,/g, "")
          : +s.Fee_1,
        Fee_2: `${s["Fee_2"]}`.includes(",")
          ? +`${s["Fee_2"]}`.replace(/,/g, "")
          : +s.Fee_2,
        Fee_3: `${s["Fee_3"]}`.includes(",")
          ? +`${s["Fee_3"]}`.replace(/,/g, "")
          : +s.Fee_3,
        Fee_10: `${s["Fee_10"]}`.includes(",")
          ? +`${s["Fee_10"]}`.replace(/,/g, "")
          : +s.Fee_10,
        Fee_11: `${s["Fee_11"]}`.includes(",")
          ? +`${s["Fee_11"]}`.replace(/,/g, "")
          : +s.Fee_11,
        SeqNo: s.SeqNo,
        Bno: s.Bno,
        MajorId: s.MajorId,
        Grade: s.Grade,
        Student: s.Student,
        Remark: s.Remark,
        Fee_1: `${s["Fee_1"]}`.includes(",")
          ? +`${s["Fee_1"]}`.replace(/,/g, "")
          : +s.Fee_1,
        Fee_2: `${s["Fee_2"]}`.includes(",")
          ? +`${s["Fee_2"]}`.replace(/,/g, "")
          : +s.Fee_2,
        Fee_3: `${s["Fee_3"]}`.includes(",")
          ? +`${s["Fee_3"]}`.replace(/,/g, "")
          : +s.Fee_3,
        Fee_4: s.Fee_4,
        Fee_5: s.Fee_5,
        Fee_6: s.Fee_6,
        Fee_7: s.Fee_7,
        Fee_8: s.Fee_8,
        Fee_9: s.Fee_9,
        Fee_10: `${s["Fee_10"]}`.includes(",")
          ? +`${s["Fee_10"]}`.replace(/,/g, "")
          : +s.Fee_10,
        Fee_11: `${s["Fee_11"]}`.includes(",")
          ? +`${s["Fee_11"]}`.replace(/,/g, "")
          : +s.Fee_11,
        Fee_12: s.Fee_12,
        Subtotal: s.Subtotal,
        MajorName: s.MajorName,
        GradeName: s.GradeName,
      }));

      const obj = {
        SeqNo: this.seqNo,
        Details: [...arr],
      };

      const url = `Budgeting/SetDetails`;
      try {
        await window.axios.put(url, obj);
        this.$bvToast.toast(`資料儲存成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.getData();
      } catch (error) {
        this.$bvToast.toast(`${error}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    openPrintPage() {
      const url = `/#/doc8-5?schoolcode=${this.editItem.SchoolCode}&byear=${this.editItem.BYear}&term=${this.editItem.Term}&category=N`;
      window.open(url);
    },
    async getUperPrice() {
      const url = `NurseCostSetting/List?schoolCode=${this.editItem.SchoolCode}`;
      const { Items } = await window.axios.get(url);
      store.uppPriceArr = [...Items];
    },
  },
  mounted() {
    this.editItem = { ...store.editItem };
    this.getUperPrice();
    this.getData();
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.dtc-main-section {
  padding: 12px;
  width: fit-content;
}
.dtc-grid-header {
  display: grid;
  grid-template-columns: 40px 120px 60px 140px 360px 600px 240px 180px;
  grid-auto-flow: column;
  text-align: center;
  padding-right: 0px;

  border: 1px solid #dee2e5;
  border-bottom: 0px;
  margin-right: 4px;
  color: #0757a7;
  > div {
    border-right: 1px solid #dee2e5;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 16px;
    padding: 4px 0;
    height: 148px;
    padding-top: 38px;
  }
  > div:last-child {
    border-right: none;
  }
  .header-column4 {
    padding-top: 0px;

    .header-column4-row1 {
      text-align: center;
      height: 55px;
      line-height: 55px;
    }
    .header-column4-row2 {
      display: grid;
      grid-template-columns: 120px 120px 120px;
      > div {
        border-right: 1px solid #dee2e5;
        border-top: 1px solid #dee2e5;
        height: 25px;
      }
    }
    .header-column4-row3 {
      display: grid;
      grid-template-columns: 120px 120px 120px;
      font-size: 13px;
      > div {
        border-right: 1px solid #dee2e5;
        border-top: 1px solid #dee2e5;
        height: 68px;
      }
      .header-column4-total {
        font-size: 16px;
      }
    }
  }
  .header-column5 {
    padding-top: 0px;

    .header-column5-row1 {
      text-align: center;
      height: 55px;
      line-height: 55px;
    }
    .header-column5-row2 {
      display: grid;
      grid-template-columns: repeat(5, 120px);
      > div {
        border-right: 1px solid #dee2e5;
        border-top: 1px solid #dee2e5;
        height: 25px;
      }
    }
    .header-column5-row3 {
      display: grid;
      grid-template-columns: repeat(5, 120px);
      font-size: 13px;
      > div {
        border-right: 1px solid #dee2e5;
        border-top: 1px solid #dee2e5;
        height: 68px;
      }
      .header-column5-total {
        font-size: 16px;
      }
    }
  }
  .header-column6 {
    padding-top: 0px;
    .header-column6-row1 {
      text-align: center;
      height: 55px;
      line-height: 55px;
    }
    .header-column6-row2 {
      display: grid;
      grid-template-columns: repeat(2, 120px);
      > div {
        border-right: 1px solid #dee2e5;
        border-top: 1px solid #dee2e5;
        height: 25px;
      }
    }
    .header-column6-row3 {
      display: grid;
      grid-template-columns: repeat(2, 120px);
      font-size: 13px;
      > div {
        border-right: 1px solid #dee2e5;
        border-top: 1px solid #dee2e5;
        height: 68px;
      }
      .header-column6-total {
        font-size: 16px;
      }
    }
  }
  .header-column-common {
    padding-top: 0px;

    .header-column-common-row1 {
      text-align: center;
      height: 55px;
      line-height: 55px;
    }
    .header-column-common-row2 {
      display: grid;

      > div {
        border-right: 1px solid #dee2e5;
        border-top: 1px solid #dee2e5;
        height: 25px;
      }
    }
    .header-column-common-row3 {
      display: grid;
      font-size: 13px;
      > div {
        border-right: 1px solid #dee2e5;
        border-top: 1px solid #dee2e5;
        height: 68px;
      }
    }
  }
}
.main-dtc-body {
  &:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  > div {
    line-height: 24px;
  }
}
.dtc-grid-main,
.dtc-grid-footer,
.dtc-grid-footer2 {
  display: grid;
  grid-template-columns: 40px 120px 60px 140px repeat(10, 120px) 180px;
  margin-right: 4px;
  grid-auto-flow: column;
  text-align: center;
  padding-right: 0px;
  border-left: 1px solid #dee2e5;
  border-right: 1px solid #dee2e5;
  border-top: 1px solid #dee2e5;
  color: #212529;
  > div {
    border-right: 1px solid #dee2e5;
    color: #212529;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 16px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  > div:last-child {
    border-right: none;
  }
}
.dtc-grid-footer {
  grid-template-columns: 360px repeat(10, 120px) 180px;
  border: 1px solid #dee2e5;
  color: #212529;

  > div {
    border-right: 1px solid #dee2e5;
    margin-top: 0px;
  }
  > div:last-child {
    border-right: none;
  }

  .column4-content-out {
    .content-row {
      > div {
        border-right: 1px solid #dee2e5;
        border-top: 1px solid #dee2e5;
      }
      > div:last-child {
        border-right: none;
      }
    }
  }
  .column5-content-out {
    .content-row {
      > div {
        border-right: 1px solid #dee2e5;
        border-top: 1px solid #dee2e5;
      }
      > div:last-child {
        border-right: none;
      }
    }
  }
}
.dtc-grid-footer2 {
  grid-template-columns: 100px 1440px;
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #333;
    color: white;
  }
}

::v-deep.btn-light {
  background: #7e2ca3;
  border-color: #7e2ca3;
  color: #fff;
}
.search-block {
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 2px 2px 0 0;
  width: 99.3%;
  margin: 0 auto 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 2px 2px 0 0;
  }
  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
    display: grid;
    max-height: 40px;
    margin-bottom: 12px;
    grid-template-columns: repeat(30, max-content);
    grid-gap: 12px;
    > * {
      max-height: 44px;
    }
  }
}
.price-content {
  text-align: right;
  padding-right: 6.5px;
}
</style>
